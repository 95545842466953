import React from "react";
import logoCupro from "src/assets/img/logos/cuprovyg.png";
import logo593 from "src/assets/img/logos/593_logo.png";
import logoBeAccess from "src/assets/img/logos/beaccess.png";
import logoEps from "src/assets/img/logos/EPS_1.png";
import logoPaladin from "src/assets/img/logos/paladin-icon.ico";
import Img from "src/assets/img/mi.png";

const LogoDown = (props) => {
  const domain = window.location.hostname;
  switch (domain) {
    case "app.cuprovyg.com":
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={logoCupro}
            style={{ width: 100, height: 90, margin: "auto" }}
            {...props}
          />
        </a>
      );
    case "app593security.micasitaapp.com":
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={logo593}
            style={{ width: 100, height: 90, margin: "auto" }}
            {...props}
          />
        </a>
      );

    case "beaccess.net":
    case "www.beaccess.net":
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={logoBeAccess}
            style={{ width: 100, height: 90, margin: "auto" }}
            {...props}
          />
        </a>
      );

    case "eps.micasitaapp.com":
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={logoEps}
            style={{ width: 100, height: 90, margin: "auto" }}
            {...props}
          />
        </a>
      );

    case "qr.paladin.com.ec":
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={logoPaladin}
            style={{
              width: 120,
              height: 110,
              margin: "auto",
            }}
            {...props}
          />
        </a>
      );
    default:
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={Img}
            style={{
              width: 140,
              height: 100,
              margin: "auto",
            }}
            {...props}
          />
        </a>
      );
  }
};

export default LogoDown;
