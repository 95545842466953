import React, { forwardRef, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import PaladinIcon from "src/assets/img/logos/paladin-icon.ico";
// eslint-disable-next-line import/no-named-as-default
import track from "../utils/analytics";

const Page = forwardRef(({ children, title = "", ...rest }, ref) => {
  const location = useLocation();
  const isPaladin = window.location.hostname === "qr.paladin.com.ec";
  const isEps = window.location.hostname === "eps.micasitaapp.com";
  const isBeAccess =
    window.location.hostname === "www.beaccess.net" ||
    window.location.hostname === "beaccess.net";
  let APP_NAME = "";

  if (isPaladin) {
    APP_NAME = "Paladin";
  } else if (isEps) {
    APP_NAME = "EPS";
  } else if (isBeAccess) {
    APP_NAME = "BeAccess";
  } else {
    APP_NAME = "Mi casita";
  }

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title ? `${title} - ${APP_NAME}` : APP_NAME}</title>
        {isPaladin ? <link rel="shortcut icon" href={PaladinIcon} /> : null}
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Page.defaultProps = {
  title: "",
};

export default Page;
