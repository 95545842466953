import React, { useState } from "react"
import { useSnackbar } from "notistack"
import { useSelector } from "react-redux"
import { CameraAlt, Cached } from "@material-ui/icons"
import ImageList from "@material-ui/core/ImageList"
import ImageListItem from "@material-ui/core/ImageListItem"
import ImageListItemBar from "@material-ui/core/ImageListItemBar"
import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import axiosInstance from "src/utils/axios"
import CustomModal from "src/components/Common/CustomModal"
import { selectCamaraGarita, selectConjunto } from "src/redux/slices/complexSlice"

const CamarasConjunto = () => {
  const { enqueueSnackbar } = useSnackbar()
  const conjunto = useSelector(selectConjunto)
  const camaraGarita = useSelector(selectCamaraGarita)

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [imagenes, setImagenes] = useState([])
  const date = new Date().toLocaleDateString()
  const time = new Date().toLocaleTimeString()

  const handleOpen = () => {
    setOpen(true)
    obtenerImagenes()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const obtenerImagenes = async () => {
    setLoading(true)
    try {
      const res = await axiosInstance.get("captura_camaras/", {
        params: { id_conjunto: conjunto.id, camaraGarita },
      })
      if (res.data !== "") {
        setImagenes(res.data.listaImagenes)
      } else {
        enqueueSnackbar("Error al obtener las imágenes", { variant: "error" })
      }
    } catch (error) {
      enqueueSnackbar("Error al obtener las imágenes", { variant: "error" })
    }
    setLoading(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleOpen}
        startIcon={<CameraAlt />}
        style={{ color:  'initial' }}
      >
        Ver Cámaras
      </Button>
      <CustomModal
        isOpen={open}
        closeModal={handleClose}
        title="Imágenes de las cámaras"
      >
        {loading ? (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box display='flex' justifyContent='space-between' alignItems='center' style={{ padding: '10px 0' }}>
              <Typography component='span' gutterBottom>
                {`Fecha: ${date} - Hora: ${time}`}
              </Typography>
              <Button
                startIcon={<Cached />}
                onClick={obtenerImagenes}
              >
                recargar
              </Button>
            </Box>
            <ImageList
              cols={2}
              rowHeight={100}
            >
              {imagenes.map((item) =>
                item.imagen ? (
                  <ImageListItem key={item.imagen} cols={1} rows={2}>
                    <img
                      src={`data: image / png; base64, ${item.imagen} `}
                      alt={item.fuente}
                    />
                    <ImageListItemBar
                      title={item.fuente}
                      position="top"
                      actionPosition="left"
                    />
                  </ImageListItem>
                ) : null
              )}
            </ImageList>
          </>
        )}
      </CustomModal>
    </>
  )
}

export default CamarasConjunto
