import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { AppBar, Box, Hidden, Toolbar, makeStyles } from "@material-ui/core";
import Account from "src/components/Topbar/Account";
import Logo from "src/components/Common/Logo";
import Notifications from "./Notifications";
import ConjuntosSelect from "./ConjuntosSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    background: () => "white",
    zIndex: "1029",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 70,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar = ({ className, onMobileNavOpen, img, ...rest }) => {
  // eslint-disable-next-line no-unused-vars

  const classes = useStyles();
  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden xsDown>
          <Logo img={img} />
        </Hidden>
        <Box ml={2} flexGrow={1} />

        <ConjuntosSelect />
        <Notifications />

        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
  className: "",
};

export default TopBar;
