import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import app, { auth } from "src/Firebase";
import { complexRef } from "src/services/complexServices";
import { Roles } from "src/utils/Constants";
import { getUserByEmail } from "src/views/usuarios/services/firestore";
import {
  getComplex,
  getRemoteModules,
  getRemoteUsers,
  getRemoteUnits,
} from "./complexSlice";

export const signIn = createAsyncThunk(
  "user/signIn",
  async ({ email, password, enqueueSnackbar }, { dispatch }) => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password);

      const userDocsSnapshot = await getUserByEmail(user.email);
      const userDocs = userDocsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const activeComplexPromises = userDocs.reduce((promises, userDoc) => {
        if(userDoc?.ConjuntoUidResidencia){
          if (!userDoc.ConjuntoUid) {
            userDoc.ConjuntoUid = [
              JSON.stringify({ uid: userDoc.ConjuntoUidResidencia }),
            ];
          }
        }
        
        if (
          !userDoc.ConjuntoUid ||
          !Array.isArray(userDoc.ConjuntoUid)
        ) {
          return promises;
        }

        const complexs = userDoc.ConjuntoUid.map((conjunto) =>
          JSON.parse(conjunto)
        );

        const validComplexPromises = complexs.map(async ({ uid }) => {
          const complexDoc = await complexRef(uid).get();
          return complexDoc.exists && complexDoc.data().isActive !== false
            ? { ...userDoc, conjuntoUid: { uid } }
            : null;
        });

        return promises.concat(validComplexPromises);
      }, []);

      const results = await Promise.all(activeComplexPromises);
      const activeComplexes = results.filter(Boolean);

      if (activeComplexes.length === 0) {
        enqueueSnackbar("Los conjuntos de este usuario están deshabilitados.", {
          variant: "error",
        });
        await auth.signOut();
        throw new Error("Sin conjuntos activos");
      }

      dispatch(setUser({ email: user.email, enqueueSnackbar }));
      return {};
    } catch (error) {
      if (error.message !== "Sin conjuntos activos") {
        enqueueSnackbar("Usuario o contraseña incorrectos", {
          variant: "error",
        });
      }
      throw error;
    }
  }
);

export const signOut = createAsyncThunk("user/singOut", async () => {
  await auth.signOut();
});

export const setUser = createAsyncThunk(
  "user/setData",
  async ({ email, enqueueSnackbar }, { dispatch }) => {
    const { docs } = await app
      .firestore()
      .collection("usuarios")
      .where("Correo", "==", email)
      .get();
    const data = { ...docs[0].data(), id: docs[0].id };
    const roles = data.Roles;
    if (
      (!roles?.superAdministrador &&
        !roles?.administrador &&
        !roles?.supervisor &&
        !roles?.parkey &&
        !roles?.empresa &&
        !roles?.directiva) ||
      data?.isActive === false
    ) {
      dispatch(signOut());
      enqueueSnackbar("¡No tienes permisos para acceder!", {
        variant: "error",
      });
      return Error("Not permissions");
    }
    const complexs =
      data.ConjuntoUid?.map((conjunto) => JSON.parse(conjunto)) || [];
    const idcomplex = complexs[0]?.uid || data?.ConjuntoUidResidencia;
    dispatch(getComplex(idcomplex));
    dispatch(getRemoteModules(idcomplex));
    dispatch(getRemoteUsers(idcomplex));
    dispatch(getRemoteUnits(idcomplex));

    return {
      id: data.id,
      name: data.Nombre,
      lastName: data.Apellido,
      email: data.Correo,
      phone: data.Telefono,
      identity: data.Cedula,
      roles: data.Roles,
      complexs,
      ConjuntoUidResidencia: data?.ConjuntoUidResidencia,
      Empresa: data.Empresa || null,
    };
  }
);

export const authSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    isAuth: false,
    error: "",
    user: {},
    rol: "",
  },
  reducers: {
    changeRol: (state, { payload }) => {
      state.rol = payload;
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.isLoading = true;
    },
    [signIn.fulfilled]: (state) => {
      state.isAuth = true;
      state.error = "";
    },
    [signIn.rejected]: (state) => {
      state.error = "El usuario o la contraseña no son validos";
      state.isLoading = false;
    },
    [setUser.pending]: (state) => {
      state.isLoading = true;
    },
    [setUser.fulfilled]: (state, { payload }) => {
      // manejar caso en login pero no tiene ningún rol habil
      let rol = "";
      const { roles = {} } = payload;
      if (roles?.superAdministrador) {
        rol = Roles.superadmin;
      } else if (roles?.administrador) {
        rol = Roles.admin;
      } else if (roles?.supervisor) {
        rol = Roles.supervisor;
      } else if (roles?.parkey) {
        rol = Roles.parkey;
      } else if (roles?.empresa) {
        rol = Roles.company;
      } else if (roles?.directiva) {
        rol = Roles.directiva;
      }
      state.user = payload;
      state.rol = rol;
      state.isLoading = false;
    },
    [setUser.rejected]: (state) => {
      state.isAuth = false;
      state.rol = "";
      state.isLoading = false;
    },
    [signOut.pending]: (state) => {
      state.isLoading = true;
    },
    [signOut.fulfilled]: (state) => {
      state.isAuth = false;
      state.isLoading = false;
      state.user = {};
      state.rol = "";
    },
    [signOut.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const selectUser = (state) => state.auth.user;
export const selectRol = (state) => state.auth.rol;

export const { changeRol } = authSlice.actions;
export default authSlice.reducer;
