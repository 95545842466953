import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRcode = ({ code, modulos }) => {
  const downloadQR = () => {
    const canvas = document.getElementById(code);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "myqr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (!code) return null;
  return (
    <QRCodeCanvas
      id={code}
      value={code}
      size={modulos?.QrSize ? 90 : 150}
      level="L"
      onClick={downloadQR}
      style={{ cursor: "pointer" }}
      title="Descargar QR"
    />
  );
};

export default QRcode;
