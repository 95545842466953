/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { firestore as db } from "src/Firebase";

export const newConjunto = (conjunto) =>
  db.collection("conjuntos").add({
    Nombre: conjunto.Nombre,
    Ruc: conjunto.Ruc,
    Imagen: conjunto.Imagen,
    NumResidentes: conjunto.NumResidentes,
    NumParqueaderos: conjunto.NumParqueaderos,
    NumeroComprobante: conjunto.NumComprobante,
    Direccion: conjunto.Direccion,
    Ciudad: conjunto.Ciudad,
    Provincia: conjunto.Provincia,
    LimiteConfianza: conjunto.LimiteConfianza,
    LimiteSesiones: conjunto.LimiteSesiones,
    UltimoMesAlicuota: conjunto.UltimoMesAlicuota,
    Empresa: conjunto.Empresa,
    NombreContacto: conjunto.NombreContacto,
    TelefonoContacto: conjunto.TelefonoContacto,
    CorreoContacto: conjunto.CorreoContacto,
    FechaCreacion: new Date(),
  });
export const updateConjunto = (conjunto, documentoId) =>
  db
    .collection("conjuntos")
    .doc(documentoId)
    .update({
      Nombre: conjunto.Nombre,
      Ruc: conjunto.Ruc,
      Imagen: conjunto.Imagen,
      NumResidentes: conjunto.NumResidentes,
      NumParqueaderos: conjunto.NumParqueaderos,
      NumeroComprobante: conjunto.NumComprobante,
      Direccion: conjunto.Direccion,
      Ciudad: conjunto.Ciudad,
      Provincia: conjunto.Provincia,
      NombreContacto: conjunto.NombreContacto,
      TelefonoContacto: conjunto.TelefonoContacto,
      CorreoContacto: conjunto.CorreoContacto,
      LimiteConfianza: conjunto.LimiteConfianza,
      LimiteSesiones: conjunto.LimiteSesiones,
      UltimoMesAlicuota: conjunto.UltimoMesAlicuota,
      Empresa: conjunto.Empresa || "",
    });

export const deleteConjuntoById = (userName) =>
  db.collection("conjuntos").doc(userName).delete();
export const getAllProvincias = () => db.collection("provincias").get();
export const getAllEmpresas = () => db.collection("empresas").get();
export const getCiudadesByProvincias = (Ciudad) =>
  db.collection("provincias").doc(Ciudad).collection("canton").get();

export const createRubros = (conjuntoID) => {
  const rubrosI = [
    {
      Tipo: "Ingreso",
      Nombre: "Ingresos ordinarios",
      SubRubros: [
        "Alícuota mensual",
        "Cuota por seguro",
        "Consumo de agua",
        "Consumo de gas",
        "Consumo de energia",
        "Saldo a favor",
      ],
    },
    {
      Tipo: "Ingreso",
      Nombre: "Saldos iniciales",
      SubRubros: ["Saldo Inicial"],
    },
    {
      Tipo: "Ingreso",
      Nombre: "Ingresos extraordinarios",
      SubRubros: [
        "Reserva áreas comunales",
        "Multas",
        "Cuota extraordinaria",
        "Intereses",
        "Notas de crédito bancarias",
        "Descuento",
      ],
    },
    {
      Tipo: "Egreso",
      Nombre: "Servicios básicos",
      SubRubros: [
        "Agua potable",
        "Energía eléctrica",
        "Consumo teléfono",
        "Servicio de internet",
        "Consumo de gas",
        "Aseguradora",
      ],
    },
    {
      Tipo: "Egreso",
      Nombre: "Salarios y beneficios de ley",
      SubRubros: [
        "Sueldos personal",
        "Décimo tercer sueldo",
        "Décimo cuarto sueldo",
        "Aporte IESS",
        "Fondos de reserva",
        "Indemnización",
        "Horas extras",
      ],
    },
    {
      Tipo: "Egreso",
      Nombre: "Mantenimientos y reparaciones",
      SubRubros: [
        "Mantenimiento de bombas y equipo hidroneumático",
        "Mantenimiento e instalaciones eléctricas",
        "Mantenimiento extintores",
        "Mantenimiento de caldero",
        "Mantenimiento equipos de agua caliente",
        "Mantenimiento e instalaciones de Gas",
        "Mantenimiento de equipos de computo",
        "Mantenimiento de ascensor",
        "Mantenimiento de generador",
        "Mantenimiento y construccion de jardines",
        "Mantenimiento de cámaras de seguridad",
        "Mantenimiento de puertas de acceso vehícular",
        "Mantenimiento puerta principal",
        "Mantenimiento cisternas",
      ],
    },
    {
      Tipo: "Egreso",
      Nombre: "Gastos administrativos",
      SubRubros: [
        "Sistema de administración",
        "Movilización varias gestiones",
        "Administración",
        "Gastos varios",
      ],
    },
    {
      Tipo: "Egreso",
      Nombre: "Seguridad",
      SubRubros: ["Servicios de guardianía"],
    },
    {
      Tipo: "Egreso",
      Nombre: "Prestacion de servicios",
      SubRubros: [
        "Servicios contables",
        "Servicios legales",
        "Servicios de limpieza",
        "Póliza contra incendios",
      ],
    },
    {
      Tipo: "Egreso",
      Nombre: "Servicios bancarios",
      SubRubros: ["Comisiones bancarias", "Débito cheques devueltos"],
    },
    {
      Tipo: "Egreso",
      Nombre: "Caja chica",
      SubRubros: [
        "Cafetería",
        "Suministros de caja oficina",
        "Suministros de limpieza",
        "Devolución garantía salón comunal",
      ],
    },
  ];
  const batch = db.batch();
  const conjuntoRef = db.collection("conjuntos").doc(conjuntoID);
  batch.set(conjuntoRef.collection("conjuntoConfig").doc("Subconjuntos"), {
    listaSubconjuntos: [],
    tiposUnidades: [],
  });
  rubrosI.forEach((rubro) => {
    const rubroRef = conjuntoRef.collection("rubros").doc(rubro.Nombre);
    batch.set(rubroRef, {
      Nombre: rubro.Nombre,
      Tipo: rubro.Tipo,
      SubRubros: rubro.SubRubros,
    });
  });
  return batch.commit();
};

export const TipoVisita = (conjuntoId, ListaVisitas) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("conjuntoConfig")
    .doc("TiposVisita")
    .set({ ListaVisitas });

export const FormadeCobroPago = (conjuntoId, FormaCobroPago) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("conjuntoConfig")
    .doc("Modulos")
    .set(FormaCobroPago);

export const Modulos = (conjuntoId) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("conjuntoConfig")
    .doc("Modulos")
    .get();

export const updateConjuntoModulos = (data, documentoId) =>
  db
    .collection("conjuntos")
    .doc(documentoId)
    .collection("conjuntoConfig")
    .doc("Modulos")
    .update(data);

export const updateEnableConjunto = (documentoId, value) =>
  db.collection("conjuntos").doc(documentoId).update({
    isActive: value.isActive,
    States: value.States,
  });

// endconjuntos
