import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplex,
  selectComplex,
  getRemoteUsers,
  getRemoteUnits,
} from "src/redux/slices/complexSlice";
import { InputAdornment, Typography } from "@material-ui/core";
import { AccountBalance } from "@material-ui/icons";
import { complexRef } from "src/services/complexServices";
import { signOut } from "src/redux/slices/authSlice";
import { useSnackbar } from "notistack";

const ConjuntosSelect = () => {
  const [complexs, setComplexs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const conjuntosId = useSelector((state) => state.auth.user.complexs);

  const selectConjunto = useSelector((state) => state.complex.selectComplex);
  const dispatch = useDispatch();
  const [selectedConjunto, setSelectedConjunto] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetchComplexes = async () => {
      try {
        const activeComplexPromises = conjuntosId.map(async (conjunto) => {
          const complex = complexRef(conjunto.uid);
          const doc = await complex.get();
          if (doc.exists && doc.data().isActive !== false) {
            return { ...conjunto };
          }
          return null;
        });
        const results = await Promise.all(activeComplexPromises);
        const conjuntosActivos = results.filter(result => result !== null);
        setComplexs(conjuntosActivos);
        if (isMounted) {
          if (conjuntosActivos.length > 0) {
            const complexSelected = conjuntosActivos.find(
              (conjunto) => conjunto.uid === conjuntosActivos[selectConjunto]?.uid
            );
            if (complexSelected) {
              setSelectedConjunto(complexSelected);
            } else {
              handleLanguageMenuClose(
                conjuntosId[selectConjunto],
                selectConjunto
              );
            }
          } else {
            enqueueSnackbar("No se encontraron conjuntos habilitados.", {
              variant: "error",
            });
            dispatch(signOut());
          }
        }
      } catch (error) {
        enqueueSnackbar("Error al cargar los conjuntos.", {
          variant: "error",
        });
      }
    };

    fetchComplexes();
    return () => {
      isMounted = false;
    };
  }, [conjuntosId]);

  const handleLanguageMenuClose = async (conjunto, index = 0) => {
    if (conjunto) {
      dispatch(getComplex(conjunto.uid));
      dispatch(getRemoteUsers(conjunto.uid));
      dispatch(getRemoteUnits(conjunto.uid));
      dispatch(selectComplex(index));
      setSelectedConjunto(conjunto);
    }
  };

  return (
    complexs && (
      <div>
        {complexs.length > 1 ? (
          <Autocomplete
            options={complexs}
            getOptionLabel={(option) => option.nombre}
            noOptionsText="No se han encontrado conjuntos"
            value={selectedConjunto}
            style={{ width: 300 }}
            onChange={(event, newValue) => {
              const index = complexs.findIndex(
                (item) => item?.uid === newValue?.uid
              );
              handleLanguageMenuClose(newValue, index);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountBalance style={{ fontSize: "1.3em" }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <Typography variant="body1" color="primary">
            {selectedConjunto?.nombre}
          </Typography>
        )}
      </div>
    )
  );
};

export default ConjuntosSelect;
