import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { getComplex, getRemoteUnits, getRemoteUsers, selectComplex, selectConjunto } from "src/redux/slices/complexSlice"

const ConjuntosSelect = () => {
  const dispatch = useDispatch()
  const complex = useSelector(selectConjunto)
  const conjuntosId = useSelector((state) => state.auth.user.complexs)
  const selectedComplex = useSelector((state) => state.complex.selectComplex)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    let isMounted = true
    if (conjuntosId.length > 0 && isMounted) {
      handleConjunto(conjuntosId[selectedComplex], selectedComplex)
    }
    return () => {
      isMounted = false
    }
  }, [conjuntosId])

  const handleClick = (event) => {
    if (conjuntosId.length > 0) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleConjunto = async (conjunto, index = 0) => {
    await dispatch(getComplex(conjunto.uid)).unwrap()
    dispatch(getRemoteUsers(conjunto.uid))
    dispatch(getRemoteUnits(conjunto.uid))
    dispatch(selectComplex(index))
    handleClose()
  }

  return conjuntosId && (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ color:  'initial' }}
      >
        {complex.Nombre}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={handleClose}
      >
        {conjuntosId.map((conjunto, index) => (
          <MenuItem
            key={conjunto.uid}
            component="a"
            data-no-link="true"
            onClick={() => handleConjunto(conjunto, index)}
          >
            {conjunto.nombre}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ConjuntosSelect
